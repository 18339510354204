<template>
  <div class="header">
    <div
      @click="backToConfig()"
      class="btn-img">
      <img src="@/assets/icon-back.png">
    </div>
  </div>
  <select-grade
    @submit="submit"
    :inProgress="false"/>
</template>

<script>
import SelectGrade from '../../components/molecule/SelectGrade.vue'

export default {
  name: 'ConfigGrade',
  components: {SelectGrade, },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    backToConfig() {
      this.$router.push('/config')
    },
    async submit(grade) {
      this.$store.commit('setConfigGrade', grade)
      await this.$store.commit('saveConfig')
      this.$router.push('/config')
    },
  }
}
</script>