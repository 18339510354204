<template>
  <div class="page-title">学年・クラス・番号の設定</div>
  <div class="flex mt-4">
    <div>
      <div class="subtitle">Grade</div>
      <div class="select mt-1">
        <select v-model="selected.year">
          <option v-for="year in years" :value="year" :key="year">
            {{year}}年
          </option>
        </select>
      </div>
    </div>
    <div>
      <div class="subtitle">Class</div>
      <div class="select mt-1">
        <select v-model="selected.class">
          <option v-for="c in classes" :value="c.id" :key="c.id">
            {{c.name}}
          </option>
        </select>
      </div>
    </div>
    <div>
      <div class="subtitle">Number</div>
      <div class="select mt-1">
        <select v-model="selected.number">
          <option v-for="number in numbers" :value="number" :key="number">
            {{number}}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div v-if="inProgress" class="btns-in-row mt-2">
    <button
      @click="back"
      class="btn-img">
      <img src="@/assets/icon-back.png"/>
    </button>
    <button
      @click="submit"
      :disabled="!isEnable"
      class="btn-img">
      <img src="@/assets/icon-next.png"/>
    </button>
  </div>
  <div v-else>
    <button
    @click="submit"
    :disabled="!isEnable"
    class="btn-bg center mt-2">Finish</button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SelectGrade',
  props: {
    inProgress: {
      type: Boolean,
      require: true,
    }
  },
  computed: {
    ...mapState(['configs',]),
    isYearSelected() {
      return year => year === this.selected?.year
    },
    isClassSelected() {
      return c => c.id === this.selected?.class
    },
    isNumberSelected() {
      return n => n === this.selected?.number
    },
    isEnable() {
      return this.selected.year && this.selected.class && this.selected.number
    }
  },
  data() {
    return {
      years: [1,2,3,4,5,6,],
      classes: [
        {id: 1, name: '1(A)組'},
        {id: 2, name: '2(B)組'},
        {id: 3, name: '3(C)組'},
        {id: 4, name: '4(D)組'},
        {id: 5, name: '5(E)組'},
        {id: 6, name: '6(F)組'},
        {id: 7, name: '7(G)組'},
        {id: 8, name: '8(H)組'},
      ],
      numbers: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,
      ],
      selected: {
        year: null,
        class: null,
        number: null,
      }
    }
  },
  created() {
    this.selected = Object.assign({}, this.configs.grade)
  },
  methods: {
    selectYear(year) {
      this.selected.year = year
    },
    selectClass(c) {
      this.selected.class = c.id
    },
    selectNumber(number) {
      this.selected.number = number
    },
    submit() {
      this.$emit('submit', this.selected)
    },
    back() {
      this.$emit('back')
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  justify-content: space-evenly;
  font-size: 25px;
}
.select {
  background: white;
  border-radius: 5px;
  border: 1px gray solid;
  max-height: 50vh;
  overflow-y: scroll;
  color: #707070;
  margin: 10px 15px;
  .row {
    cursor: pointer;
    padding: 5px 30px;
  }
  .selected, .row:hover {
    background: #B3D3FE;
  }
}
.subtitle {
  width: 150px;
  padding: 5px;
  text-align: center;
  color: white;
  background: linear-gradient(to left, #28529D, #0E1D3A);
  font-weight: bold;
}
</style>

